body {
  font-family: 'Arial',sans-serif;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #000000;
}

.card {
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f7f5f9;
  width: 100%;
  max-width: 600px;
  padding: 4px;
  margin-bottom: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-image {
  flex: 0 0 auto; 
  margin-right: 10px;
}
.card-image img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  border-radius: 8px;
  background-color: #f0f0f000;
}

.card-content {
  padding-left: 2px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  text-align: left;
}

.product-name {
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #333;
  margin-bottom: 8px;
}

.pricing {
  display: flex;
  font-family: 'Arial', sans-serif;
  align-items: baseline;
  margin-bottom: 5px;
}

.discounted-price {
  font-size: 18px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: rgb(65, 71, 67);
  margin-right: 4px;
}

.price-unit {
  font-size: 10px;
  color: #666;
}

.quantity-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
}

.quantity-controls button {
  background-color: #ffffff;
  color: #5D4037;
  border: 1px solid #e0e0e0;
  border-radius: 20%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0 4px;
  transition: all 0.2s ease;
}

.quantity-controls button:hover {
  background-color: #4CAF50;
  color: #ffffff;
  border-color: #4CAF50;
}

.quantity-controls .quantity {
  font-size: 14px;
  font-family: 'Arial', sans-serif;
  width: 20px;
  text-align: center;
  margin: 0 4px;
}

.add-button {
  background-color: #ffffff;
  color: #4CAF50;
  border: 1px solid #4CAF50;
  border-radius: 4px;
  font-family: 'Arial', sans-serif;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 8px;
  transition: all 0.2s ease;
}

.add-button:hover {
  background-color: #4CAF50;
  color: #ffffff;
}

.add-button {
  background-color: #4CAF50;
  color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 8px 16px;
  font-family: 'Arial', sans-serif;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  transition: all 0.3s ease;
  outline: none;
}

.add-button:hover {
  background-color: #45a049;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

.add-button:active {
  background-color: #3d8b40;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
}
@media (max-width: 768px) {
  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 12px;
  }

  .card-image {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .card-image img {
    width: 60px;
    height: 60px;
  }

  .product-name {
    font-size: 16px;
  }

  .pricing {
    flex-direction: column;
    align-items: flex-start;
  }

  .discounted-price {
    font-size: 18px;
  }

  .price-unit {
    font-size: 12px;
  }

  .quantity-controls {
    margin-top: 8px;
  }

  .quantity-controls button {
    font-size: 14px;
    padding: 4px 8px;
  }

  .quantity-controls .quantity {
    font-size: 14px;
  }

  .add-button {
    font-size: 14px;
    padding: 8px 8%;
    font-family: 'Arial', sans-serif;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .card {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  .card-image img {
    width: 50px;
    height: 50px;
  }

  .product-name {
    font-size: 14px;
  }
  .discounted-price {
    font-size: 16px;
  }
  .price-unit {
    font-size: 10px;
  }
 
  .quantity-controls button {
    margin-top: 30px;
    text-align: center;
    font-size: 12px;
    padding: 3px 10px;
  }

  .quantity-controls .quantity {
    margin-top: 30px;
    font-size: 12px;
  }
.catname{
  background-color:rgba(77, 42, 12, .234);
  border-radius: 10px;
}
  .add-button {
    font-size: 12px;
    padding: 2px 4px;
    font-family: 'Arial', sans-serif;
    width: 50%;
    text-align: center;
    margin-top: 40px;
    padding-top: 6px;
    margin-right: 4px;;
  }
}