*{
  font-family: 'Arial', sans-serif;;
  
}
.app-container {
  display: flex;
  height: 100vh;
}
.categorycss{
  border:2px solid rgb(188, 185, 188) ;
  border-radius: 10px;
  margin-bottom: 10px;
}
.fin{
  padding: 60px;
  padding-top: 7px;
  background-color: rgb(0, 0, 0);
}
.left-side {    
  overflow-y: auto;
  padding: 80px;
  background-color: rgb(255, 255, 255);
}
.right-side {
  background-color: white;
  padding-left: 10px;
  margin-top: 0%;
  width: 40%; 
  overflow-y: auto;
  box-sizing: border-box;
}
.social{
  display: flex;
  flex-direction: row;
  gap: 30px;
  
}
.catname{
  background-color: purple;
  color: #ffffff; 
  font-size: 8px;
  font-family: 'Arial', sans-serif;
  text-align: center;
  padding: 2px;
  border-radius: 0%;  
}
@media (max-width: 768px) {
  .app-container {
    display: block;
    height:auto;
  }
.fin{
  padding: 8px;
}
.left-side{
  width: 80%;
  border-left: none;
  height: auto;
}
  .right-side {
    width: 80%;
    border-left: none;
    height: auto;
  }
} 
.body{
  font-family: 'Arial', sans-serif;
  background-color: rgb(0, 0, 0);
}
.app-container {
  display: flex;
  height: 100vh;
}
.aro{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.arrow{
  /* margin-top: 8px; */
  padding-right: 5px;
}
.categorycss {
  border: 1.8px solid rgba(61, 38, 31, 0.536);
  border-radius: 12px;
  margin-bottom: 8px;
  text-align: left;
}
.left-side {
  margin: 0%;
  margin-right: 10px;
  border: solid black;
  border-radius: 22px;
  width: 60%; 
  overflow-y: scroll;
  padding: 18px;
  scrollbar-width: none;
  -ms-overflow-style: none; 
}

.left-side::-webkit-scrollbar {
  display: none; 
}

.right-side {
  border: 2px solid #000000;
  border-radius: 22px;
  padding-left: 12px;
  width: 40%; 
  border-left: 1px solid #ccc;
  overflow-y:scroll;
  box-sizing: border-box;
  scrollbar-width: none; 
  -ms-overflow-style: none;
}
.right-side::-webkit-scrollbar {
  display: none; 
}
.catname {
  background-color: rgba(77, 42, 12, 0.234);
  color: #010101; 
  font-size: 12px;
  font-family: 'Arial', sans-serif;
  border-radius: 11px;
  text-align: left;
  padding-top: 2px;
  padding-bottom: 2px;
  padding: 1px; /* Rounded corners */
}

@media (max-width: 768px) {
  .app-container {
    display: block;
    height: auto;
  }
  .left-side {
    width: 100%;
    padding: 6px;
    height: auto;
    min-width: 100px;
  }
  .right-side {
    width: 100%;
    padding: 6px;
    border-left: none;
    height: auto;
  }
}
